import api from '@/api/';
import router from '../../router'
import axios from 'axios';
export default {
  namespaced: true,
  state() {
    return {
      simInfo: null,
      curr_pay: undefined,
      curr_win: undefined,
      currCert: {},
      promocode: {}, 
      appLoad: false, 
      lessons: null
    }
  },
  getters: {
    getSimInfo: stateFreezed => stateFreezed.simInfo,
    getCurrPay: stateFreezed => stateFreezed.curr_pay,
    getCurrWin: stateFreezed => stateFreezed.curr_win,
    getPromocode: stateFreezed => stateFreezed.promocode,
    getCurrCert: stateFreezed => stateFreezed.currCert,
    getLessons: stateFreezed => stateFreezed.lessons

  },
  actions: {
    async SEND_NOTIFICATION(context, value) {
      if (context.state.simInfo.notifications_url) {
        const data = new FormData();
        console.log(value)
        data.append("simulator", context.state.simInfo.id);
        data.append("user_id", context.rootState.user.meInfo.id);
        data.append("user_email", context.rootState.user.meInfo.email);
        data.append("type", value.type);
        if (value.id)
          data.append("id", value.id);
        if (value.text)
          data.append("text", value.text);
        if (value.answer)
          data.append("answer", value.answer);
        if (value.answer_text)
          data.append("answer_text", value.answer_text);
        if (value.name)
          data.append("name", value.name);
        if (value.surname)
          data.append("surname", value.surname);
        if (value.utm)
          data.append("utm", value.utm);
        if (value.gender)
          data.append("gender", value.gender);
        if (value.lesson_id)
          data.append("lesson_id", value.lesson_id);
        if (value.lesson_name)
          data.append("lesson_name", value.lesson_name);
        if (value.page_id)
          data.append("page_id", value.page_id)
        if (value.page_name)
          data.append("page_name", value.page_name)
        axios.post(context.state.simInfo.notifications_url, data)
      }
    },
    async FETCH({ commit }) {
      await api.get('simulators/details.json')
      .then(response => {
        commit('SET_SIM_INFO', response.data)
      })
      .catch(function (error) {
        if (error.response.status == 500) {
          commit("user/SET_LOGOUT", null, { root: true });
          router.push({ path: '/register' })
        }
      })
    },
    async LESSONS_FETCH({ commit }) {
      await api.get(`lessons.json`)
      .then(response => {
        commit('SET_SIM_LESSONS', response.data)
      })
      .catch(function (error) {
        if (error.response.status == 500) {
          // commit("user/SET_LOGOUT", null, { root: true });
          // router.push({ path: '/login' })
        }
      })
    },
    async PAY(context) {
      const windowPay = window.open("about:blank", "", "height=600,width=600")
      windowPay.document.write("Перенаправляем в платежный сервис...")

      const data = new FormData();
      if (context.getters.getPromocode.promo_code) {
        data.append("promo_code", context.getters.getPromocode.promo_code)
      }

      const response = await api.post('payments/pay/', data)

      if (response && response.status === 200) {
        windowPay.location.replace(response.data.confirmation_url)
        windowPay.focus()

        let timer = setInterval(() => {
          api.get(`payments/${response.data.id}.json`)
          .then(result => {
            if (result.data.status === 2) {
              windowPay.close()
              context.dispatch('user/FETCH', null, {root:true})
              clearInterval(timer)
            }
          })
          .catch((error) => {
            console.log(`error`, error)
            windowPay.close()
          })
        }, 10000)
      }
    },
    async ACTIVATE_PROMOCODE(context, window) {
      const data = new FormData()
      data.append('promo_code', window.promocode)
      
      await api.post(`payments/activate/`, data)
      .then(response => {
        if (response.data.success) {
          context.commit('SET_CURR_PROMOCODE', response.data)
          window.$bvToast.toast('Цена на симулятор изменена', {
            title: 'Промокод применен!',
            variant: 'success',
            solid: true
          })
        } else {
          window.$bvToast.toast('Попробуйте другой промокод', {
            title: 'Промокод больше неактивен',
            variant: 'danger',
            solid: true
          })
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          window.$bvToast.toast('Попробуйте другой промокод', {
            title: 'Такого промокода нет',
            variant: 'danger',
            solid: true
          })
        }
      })
    },
    async FETCH_CERTS(context) {
      await api.get('api/my_certificates/')
      .then(response => {
        if (response.data.length === 1) {
          context.commit("SET_CURR_CERT", response.data[0]);
        }
      })
      .catch(error => {
        console.log(`error`, error)
      })
    },
    async SET_CERT_IMAGE(context, cert) {
      const data = new FormData()
      data.append("image", cert.img)

      await api.post(`api/set_cert_image/${cert.slug}/`, data)
      .then(response => {
        return response
      })
      .catch(error => {
        console.log(`error`, error)
      });
    }
  },
  mutations: {
    SET_SIM_INFO(state, response) {
      state.simInfo = response
    },
    SET_SIM_LESSONS(state, response) {
      state.lessons = response
    },
    SET_CURR_CERT(state, response) {
      state.currCert = response
    },
    SET_CURR_PROMOCODE(state, response) {
      state.promocode = response;
    }
  }
}
